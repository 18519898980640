import Vue from "vue";
import store from "@/store/store";
import axios from "axios";
import '@/assets/http';
import '@/utils/publicImportMainShopping';
import '@/components/localComponents/';
import '@/utils/tools/index';
import router from "./router"; // 路由
import webSocket from "@/utils/webSocket";
import {i18n} from "@/locale";

Vue.prototype.axios = axios;
Vue.prototype.$webSocket = webSocket;
store.commit('system', 'shopping');
new Vue({
  router,
  i18n,
  store, // 引入store配置
}).$mount("#app");
